export const staticPrimaryColor = '#ea5631'

const theme = {
  defaultTheme: 'light',
  themes: {
    light: {
      dark: false,
      colors: {
        primary: staticPrimaryColor,
        'on-primary': '#fff',
        secondary: '#7C819B',
        'on-secondary': '#edeef2',
        background: '#F5F5F9',
        'on-background': '#32475C',
        'on-surface': '#242D59',
        'grey-50': '#FAFAFA',
        'grey-100': '#EBEEF0',
        'grey-200': '#EEEEEE',
        'grey-300': '#E0E0E0',
        'grey-400': '#BDBDBD',
        'grey-500': '#9E9E9E',
        'grey-600': '#757575',
        'grey-700': '#616161',
        'grey-800': '#424242',
        'grey-900': '#212121',
        success: '#15C16A',
        'on-success': '#fff',
        warning: '#FFC107',
        'on-warning': '#fff',
        error: '#ED5A5A',
        'on-error': '#fff',
        info: '#2D9CDB',
        'on-info': '#fff'
      },
      variables: {
        'medium-emphasis-opacity': 1,
        'high-emphasis-opacity': 1,
        'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.06)',
        'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.04)',
        'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.02)'
      }
    },
    dark: {
      dark: true,
      colors: {
        primary: staticPrimaryColor,
        secondary: '#808085',
        success: '#15C16A',
        'on-success': '#fff',
        warning: '#FFC107',
        'on-warning': '#fff',
        error: '#ED5A5A',
        'on-error': '#fff',
        info: '#2D9CDB',
        'on-info': '#fff'
      },
      variables: {
        'medium-emphasis-opacity': 1,
        'high-emphasis-opacity': 1,
        'shadow-key-umbra-opacity': 'rgba(20, 21, 33, 0.06)',
        'shadow-key-penumbra-opacity': 'rgba(20, 21, 33, 0.04)',
        'shadow-key-ambient-opacity': 'rgba(20, 21, 33, 0.02)'
      }
    }
  }
}

export default theme
