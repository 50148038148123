import { Ability } from '@casl/ability'

export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth'
  }
]

export default new Ability(initialAbility)
