import { canNavigate } from '@/plugins/casl/utils'
import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router/auto'
import { routes } from 'vue-router/auto-routes'
import { useUserStore } from '@/stores/user'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    ...setupLayouts(routes)
  ]
})

router.beforeEach(async to => {
  const userStore = useUserStore()
  await userStore.authenticate()
  const isLoggedIn = userStore.isLoggedIn

  if (canNavigate(to)) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      return '/'
    }
  } else {
    if (isLoggedIn) {
      return { name: '/not-authorized' }
    } else {
      return { name: '/login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
    }
  }
})

export default router
