<script setup>
import { useTheme } from 'vuetify'
import { useConfigStore } from '@/stores/config'
import Toaster from '@/components/Toaster.vue'

const { isRtl } = storeToRefs(useConfigStore())

// Sync vuetify theme with config store state
const vuetifyTheme = useTheme()
const { theme } = storeToRefs(useConfigStore())
const isDarkPreferred = usePreferredDark()

const effectiveTheme = computed(() => {
  return theme.value === 'system'
    ? isDarkPreferred.value
      ? 'dark'
      : 'light'
    : theme.value
})

vuetifyTheme.global.name.value = effectiveTheme.value
watch(effectiveTheme, val => {
  vuetifyTheme.global.name.value = val
})

// Sync config store i18nLocale with vue-i18n locale
const { i18nLocale } = storeToRefs(useConfigStore())
const { locale } = useI18n({ useScope: 'global' })
locale.value = i18nLocale.value
watch(i18nLocale, val => {
  locale.value = val
})
</script>

<template>
  <VLocaleProvider :rtl="isRtl">
    <VApp>
      <RouterView />

      <Toaster />
    </VApp>
  </VLocaleProvider>
</template>
