export default {
  global: {
    ripple: false
  },
  IconBtn: {
    icon: true,
    color: 'default',
    variant: 'text',
    VIcon: {
      size: 24
    },
    width: 48,
    height: 48
  },
  VAlert: {
    density: 'comfortable',
    VBtn: {
      color: undefined
    }
  },
  VAppBarNavIcon: {
    width: 48,
    height: 48
  },
  VAvatar: {
    // ℹ️ Remove after next release
    variant: 'flat'
  },
  VBtn: {
    elevation: 0
  },
  VChip: {
    variant: 'tonal',
    elevation: 0,
    density: 'comfortable'
  },
  VList: {
    VListItem: {
      color: 'primary'
    }
  },
  VPagination: {
    density: 'compact'
  },
  VTabs: {
    // set v-tabs default color to primary
    color: 'primary',
    VSlideGroup: {
      showArrows: true
    }
  },
  VTooltip: {
    // set v-tooltip default location to top
    location: 'top'
  },
  VCheckboxBtn: {
    color: 'primary'
  },
  VCheckbox: {
    // set v-checkbox default color to primary
    color: 'primary',
    density: 'comfortable',
    hideDetails: 'auto'
  },
  VRadioGroup: {
    color: 'primary',
    density: 'comfortable',
    hideDetails: 'auto'
  },
  VRadio: {
    density: 'comfortable',
    hideDetails: 'auto'
  },
  VSelect: {
    density: 'comfortable',
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto'
  },
  VRangeSlider: {
    // set v-range-slider default color to primary
    thumbSize: 14,
    color: 'primary',
    density: 'comfortable',
    thumbLabel: true,
    hideDetails: 'auto'
  },
  VRating: {
    // set v-rating default color to primary
    color: 'rgba(var(--v-theme-on-background),0.22)',
    activeColor: 'warning'
  },
  VProgressCircular: {
    // set v-progress-circular default color to primary
    color: 'primary'
  },
  VSlider: {
    // set v-slider default color to primary
    thumbSize: 14,
    color: 'primary',
    hideDetails: 'auto'
  },
  VTextField: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto',
    density: 'comfortable'
  },
  VAutocomplete: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto',
    density: 'comfortable',
    VChip: {
      density: 'default',
      size: 'default'
    }
  },
  VCombobox: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto'
  },
  VFileInput: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto',
    density: 'comfortable'
  },
  VTextarea: {
    variant: 'outlined',
    density: 'comfortable',
    color: 'primary',
    hideDetails: 'auto'
  },
  VSwitch: {
    // set v-switch default color to primary
    color: 'primary',
    hideDetails: 'auto'
  },
  VDateInput: {
    variant: 'outlined',
    color: 'primary',
    density: 'comfortable'
  },
  VDatePicker: {
    color: 'primary',
    density: 'comfortable'
  },
  VCardActions: {
    VBtn: {
      variant: 'elevated'
    }
  }
}
