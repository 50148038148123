import { defineStore } from 'pinia'
import client from '@/plugins/apollo/client'
import { initialAbility } from '@/plugins/casl/ability'

export const useUserStore = defineStore('user', {
  state: () => ({
    init: false,
    currentUser: null,
    currentUserLoading: false,
    currentUserFetchError: null,
    currentUserErrors: null,
    loginLoading: false,
    loginFetchError: null,
    loginErrors: null,
    logoutLoading: false,
    logoutFetchError: null,
    logoutErrors: null,
    sendResetPasswordInstructionsLoading: false,
    sendResetPasswordInstructionsFetchError: null,
    sendResetPasswordInstructionsErrors: null,
    resetPasswordByTokenLoading: false,
    resetPasswordByTokenFetchError: null,
    resetPasswordByTokenErrors: null
  }),
  getters: {
    isLoggedIn: (state) => !!state.currentUser
  },
  actions: {
    async authenticate() {
      if (!this.init) {
        this.init = true
        this.currentUserLoading = true
        this.currentUserFetchError = null
        this.currentUserErrors = null

        const query = gql`
          query {
            currentUser {
              id
              email
              rules
            }
          }
        `

        try {
          const { data } = await client.query({ query, fetchPolicy: 'network-only' })

          if (data.currentUser) {
            this.currentUser = data.currentUser
            this.ability.update(data.currentUser.rules)
          } else {
            this.currentUser = null
          }
        } catch (error) {
          this.currentUserFetchError = error
        } finally {
          this.currentUserLoading = false
        }
      }
    },
    async login({ email, password, rememberMe }) {
      this.loginLoading = true
      this.loginFetchError = null
      this.loginErrors = null

      const mutation = gql`
        mutation($input: LoginInput!) {
          login(input: $input) {
            user {
              id
              email
              rules
            }
            errors
          }
        }
      `

      const variables = {
        input: {
          email,
          password,
          rememberMe
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.login.errors) {
          this.loginErrors = data.login.errors
        } else {
          this.currentUser = data.login.user
          this.ability.update(data.login.user.rules)

          const to = this.router.currentRoute.value.query.to
          this.router.replace(to ? String(to) : '/')
          toast.success(this.i18n.t('pages.login.toast.success'))
        }
      } catch (error) {
        this.loginFetchError = error
      } finally {
        this.loginLoading = false
      }
    },
    async logout() {
      this.logoutLoading = true
      this.currentUser = null

      const mutation = gql`
        mutation($input: LogoutInput!) {
          logout(input: $input) {
            user {
              email
            }
          }
        }
      `

      const variables = {
        input: {}
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.logout.errors) {
          this.logoutErrors = data.logout.errors
        } else {
          this.currentUser = null
          this.ability.update(initialAbility)
          await this.router.push({ name: '/login' })
        }
      } catch (error) {
        this.logoutFetchError = error
      } finally {
        this.logoutLoading = false
      }
    },
    async sendResetPasswordInstructions({ email }) {
      this.sendResetPasswordInstructionsLoading = true
      this.sendResetPasswordInstructionsFetchError = null
      this.sendResetPasswordInstructionsErrors = null

      const mutation = gql`
        mutation ($input: SendResetPasswordInstructionsInput!) {
          sendResetPasswordInstructions(input: $input) {
            success
            errors
          }
        }
      `

      const variables = {
        input: {
          email
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.sendResetPasswordInstructions.success) {
          this.router.push({ name: '/login' })
          toast.success(this.i18n.t('pages.forgotPassword.toast.success'))
        } else {
          this.sendResetPasswordInstructionsErrors = data.sendResetPasswordInstructions.errors
        }
      } catch (error) {
        this.sendResetPasswordInstructionsFetchError = error
      } finally {
        this.sendResetPasswordInstructionsLoading = false
      }
    },
    async resetPasswordByToken({ password, passwordConfirmation, resetPasswordToken }) {
      this.resetPasswordByTokenLoading = true
      this.resetPasswordByTokenFetchError = null
      this.resetPasswordByTokenErrors = null

      const mutation = gql`
        mutation ($input: ResetPasswordByTokenInput!) {
          resetPasswordByToken(input: $input) {
            success
            errors
          }
        }
      `

      const variables = {
        input: {
          password,
          passwordConfirmation,
          resetPasswordToken
        }
      }

      try {
        const { data } = await client.mutate({ mutation, variables })

        if (data.resetPasswordByToken.success) {
          this.router.push({ name: '/login' })
          toast.success(this.i18n.t('pages.resetPassword.toast.success'))
        } else {
          this.resetPasswordByTokenErrors = data.resetPasswordByToken.errors
          if (this.resetPasswordByTokenErrors?.resetPasswordToken) {
            toast.error(this.i18n.t('pages.resetPassword.toast.invalidToken'))
          }
        }
      } catch (error) {
        this.resetPasswordByTokenFetchError = error
      } finally {
        this.resetPasswordByTokenLoading = false
      }
    }
  }
})
